import React, { Component } from 'react'
import { TransitionContext } from '../../context'
import Fade from './Fade'

export default class Transition extends Component {
  render() {
    return (
			<>
        {/* <Panel {...this.props}>{this.props.children}</Panel> */}
        <Fade {...this.props}>{this.props.children}</Fade>
			</>
    )
  }
}

Transition.contextType = TransitionContext
import React from 'react'
import { Link } from 'gatsby'
import FooterNavigation from './FooterNavigation'
import Logo from '../../assets/images/logo.svg'
import styled from 'styled-components'
import { Container, Row, Col } from 'react-grid-system'

const FooterWrapper = styled.footer`
	background-color: ${props => props.theme.colors.gray};
	margin-top: 30px;
	padding: 10px 0;
`

const FooterLogo = styled(Link)`
	display: flex;

	svg {
		width: 50px;
		height: 50px;
	}
`

const Footer = () => (
	<FooterWrapper>
		<Container>
			<Row justify="between" align="center">
				<Col xs="content">
					<FooterLogo to="/" aria-label="Accueil">
						<Logo />
					</FooterLogo>
				</Col>
				<Col xs="content">
					<FooterNavigation />
				</Col>
			</Row>
		</Container>
	</FooterWrapper>
)

export default Footer

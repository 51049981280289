import React, { Component, createRef } from 'react'
import { SwitchTransition, Transition } from 'react-transition-group'
import styled from 'styled-components'

const FadeBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: white;
  pointer-events: none;
  z-index: 9999;
  opacity: ${props => props.alpha};
  transition: opacity 0.3s cubic-bezier(0.23, 1, 0.32, 1);
`

export default class CustomTransition extends Component {
	constructor(props) {
    super(props)
    
    this.background = createRef()
    this.state = {
      alpha: 0
    }
		this.handleExit = this.handleExit.bind(this)
    this.handleEnter = this.handleEnter.bind(this)
    this.handleEntered = this.handleEntered.bind(this)
  }

	handleExit() {
    this.setState({ alpha: 1 })
	}

	handleEnter() {
    window.scroll && window.scroll.setScroll(0, 0)
    this.setState({ alpha: 0 })
  }
  
  handleEntered() {
    window.scroll && window.scroll.update()
  }

  render() {
    return (
			<>
        <SwitchTransition mode="out-in">
          <Transition
            key={this.props.location.pathname}
            timeout={300}
            onExit={this.handleExit}
            onEnter={this.handleEnter}
            onEntered={this.handleEntered}
          >
            {this.props.children}
          </Transition>
        </SwitchTransition>
        <FadeBackground alpha={this.state.alpha} ref={this.background}/>
			</>
    )
  }
}
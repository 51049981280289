import React from 'react'
import CookieConsent from 'react-cookie-consent'
import styled from 'styled-components'
import { up } from 'styled-breakpoints'

const CookieWrapper = styled.div`
  .cookie-notice {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 15px 30px;
    background-color: black;
    z-index: 10;

    &__text {
      font-family: Arial, Helvetica, sans-serif;
      font-size: 13px;
      color: white;
      padding-right: 20px;
      text-align: left;

      ${up('sm')} { text-align: center; }
    }

    &__accept {
      font-family: Arial, Helvetica, sans-serif;
      padding: 10px;
      background-color: transparent;
      color: white;
      border: 1px solid white;
      cursor: pointer;
      transition:
        background-color 0.5s ${props => props.theme.eases.out},
        color 0.5s ${props => props.theme.eases.out};

      &:hover {
        background-color: white;
        color: black;
      }
    }

    &__decline {
      font-family: Arial, Helvetica, sans-serif;
      padding: 10px;
      background-color: transparent;
      color: white;
      border: 1px solid white;
      cursor: pointer;
      margin-bottom: 10px;
      transition:
        background-color 0.5s ${props => props.theme.eases.out},
        color 0.5s ${props => props.theme.eases.out};

      &:hover {
        background-color: white;
        color: black;
      }

      ${up('sm')} { 
        margin-bottom: 0;
        margin-right: 10px;
      }
    }
  }
`

const Cookie = (props) => {
  return (
    <CookieWrapper>
      <CookieConsent
        location="bottom"
        buttonText="J'accepte"
        declineButtonText="Je n'accepte pas"
        cookieName="gatsby-gdpr"
        disableStyles={true}
        containerClasses="cookie-notice"
        contentClasses="cookie-notice__text"
        buttonClasses="cookie-notice__accept"
        declineButtonClasses="cookie-notice__decline"
        enableDeclineButton={true}>
        Nous utilisons des cookies pour vous garantir la meilleure expérience sur notre site web. Si vous continuez à utiliser ce site, nous supposerons que vous en êtes satisfait.
      </CookieConsent>
    </CookieWrapper>
  )
}


export default Cookie
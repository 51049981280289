import React, { Component, createRef } from 'react'
import gsap from 'gsap'
import Splitting from 'splitting'

export default class SplitText extends Component {
  constructor(props) {
    super(props)

    this.baseDelay = this.props.baseDelay || 0
    this.delay = this.props.delay || 0
    this.splitBy = this.props.splitBy || 'words'
    this.splittedText = createRef()
  }

  componentDidMount() {
    this.split()
  }

  show() {
    gsap.fromTo(this.getSplittedText().chars, {
      alpha: 0
    }, {
      alpha: 1,
      duration: 3,
      ease: 'power3.out',
      stagger: 0.5
    })
  }

  split() {
    this.results = Splitting({target: this.splittedText.current, by: this.splitBy })
		if (this.splitBy === 'words') {
			this.results[0].words.map((word, index) => {
        word.style.transitionDelay = this.baseDelay + (index * this.delay) + 's'
        return null
			})
		} else if (this.splitBy === 'chars') {
			this.results[0].chars.map((char, index) => {
				char.style.transitionDelay = this.baseDelay + (index * this.delay) + 's'
        char.style.animationDelay = this.baseDelay + (index * this.delay) + 's'
        return null
			})
		} else if (this.splitBy === 'lines') {
			this.results[0].lines.map((line, lineIndex) => {
				line.map(word => {
          word.classList.add(`line-${lineIndex}`)
          return null
        })
        return null
			})
		}
  }

  getSplittedText() {
    return this.results[0]
  }

  render() {
    return(
      <span ref={this.splittedText}>
			  {this.props.children}
		  </span>
    )
  }
}
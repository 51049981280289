import React from 'react'
import { useMenuData } from '../hooks/useMenuData'
import { useRelative } from '../hooks/useRelative'
import { Link } from 'gatsby'
import styled from 'styled-components'

const NavigationWrapper = styled.div``

const NavigationItems = styled.ul`
	display: flex;
	margin: 0;
	padding: 0;
`

const NavigationItem = styled.li`
	list-style-type: none;
	margin: 0 10px;

	&:first-child { margin-left: 0; }
	&:last-child { margin-right: 0; }
`

const NavigationItemLink = styled(Link)`
	color: black;

	&.active { text-decoration: underline; }
`

const Navigation = (props) => {
	const menuData = useMenuData()
	const headerData = menuData.nodes.find(menu => menu.locations[0] === 'PRIMARY_NAVIGATION')
	const items = headerData && headerData.menuItems.nodes

	return (
		<>
			{items &&
				<NavigationWrapper toggle={props.toggle}>
					<nav>
						<NavigationItems>
							{items.map(
								(item, index) => <MenuItem key={`item-${index}`} {...item} />
							)}
						</NavigationItems>
					</nav>
				</NavigationWrapper>
			}
		</>
	)
}

const MenuItem = ({ label, url }) => {
	const relativeUrl = useRelative(url)

	return (
		<NavigationItem>
			<NavigationItemLink to={relativeUrl} activeClassName={'active'}>{label}</NavigationItemLink>
		</NavigationItem>
	)
}

export default Navigation
import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  body {
    overflow-x: hidden;
    padding: 0 !important;
    margin: 0 !important;
    font-family: ${props => props.theme.fonts.content};

    &.overflow-hidden { overflow: hidden; }
  }

  * { box-sizing: border-box; }
  a { text-decoration: none; }
`

export default GlobalStyle
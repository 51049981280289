import React from 'react'
import { Link } from 'gatsby'
import { useMenuData } from '../hooks/useMenuData'
import { useRelative } from '../hooks/useRelative'
import DC from '../../assets/images/dc.svg'
import styled from 'styled-components'
import { Row, Col } from 'react-grid-system'

const FooterItemWrapper = styled.div`
	list-style-type: none;
	margin-right: 10px;

	&:last-child { margin-right: 0; }
`

const FooterItem = styled(Link)`
	color: black;

	&.active { text-decoration: underline; }
`

const FooterLogo = styled.div`
	display: flex;

	svg {
		width: 30px;
		height: 30px;
	}
`

const FooterLink = styled.a`
	margin-right: 10px;
	margin-left: 5px;
`



const FooterNavigation = () => {
	const menuData = useMenuData()
	const footerData = menuData.nodes.find(menu => menu.locations[0] === 'PRIMARY_NAVIGATION')
	const items = footerData && footerData.menuItems.nodes

	return (
		<>
			{items &&
				<div className="footer-navigation">
					<Row align="center" nogutter>
						{items.map(
							(item, index) => <MenuItem key={`item-${index}`} {...item} />
						)}
						<Credit />
					</Row>
				</div>
			}
		</>
	)
}

const Credit = () => {
	const date = new Date().getFullYear()

	return (
		<div>
			<Row align="center" nogutter>
				<Col xs="content"><div>{'©' + date + ' - '}</div></Col>
				<Col xs="content">
					<FooterLink href="https://digital-cover.fr/" target="_blank" rel="noopener noreferrer">Création de site internet</FooterLink>
				</Col>
				<Col xs="content">
					<FooterLogo>
						<DC />
					</FooterLogo>
				</Col>
			</Row>
		</div>
	)
}

const MenuItem = ({ label, url }) => {
	const relativeUrl = useRelative(url)

	return (
		<FooterItemWrapper>
			<FooterItem activeClassName='active' to={relativeUrl}>{label}</FooterItem>
		</FooterItemWrapper>
	)
}

export default FooterNavigation
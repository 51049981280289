import React, { Component, createRef } from 'react'
import Toggler from './Toggler'
import Navigation from './Navigation'
import { Link } from 'gatsby'
import { checkMobile } from '../../helpers'
import styled from 'styled-components'
import Logo from '../../assets/images/logo.svg'
import { Container, Row, Col } from 'react-grid-system'

const HeaderWrapper = styled.header`
	position: fixed;
	width: 100%;
	background: transparent;
	padding: 10px 0;
	z-index: 10;
	transition: transform 0.75s ${props => props.theme.eases.out};

	&--hidden { transform: translateY(-100%); }
`

const LogoWrapper = styled(Link)`
	display: flex;
	text-transform: uppercase;
	font-size: 30px;
	font-family: ${props => props.theme.fonts.headings};
	color: white;

	svg {
		width: 50px;
		height: 50px;
	}
`

export default class Header extends Component {
	constructor(props) {
		super(props)
		
		this.state = {
			menuOpen: false
		}

		this.header = createRef()

		this.isMobile = checkMobile()
		this.isShowing = false
	}

	componentDidMount() {
		if (this.isMobile) window.addEventListener('scroll', this.handleScroll.bind(this))
		else window.addEventListener('customScrollEvent', this.handleScroll.bind(this))
	}

	handleScroll(e) {
		if (!this.props.smartHeader) return

		const offset = this.props.offset || window.innerHeight
		
		this.lastScroll = this.currentScroll ? this.currentScroll : 0
		this.currentScroll = this.isMobile ? window.pageYOffset : e.detail.y

		if (this.currentScroll < offset) this.showHeader()
		else {
				if (this.lastScroll > this.currentScroll) this.showHeader()
				if (this.lastScroll < this.currentScroll) this.hideHeader()
		}
	}

	hideHeader() {
		this.isShowing = false
		this.header.current.classList.add('header--hidden')
	}

	showHeader() {
		this.isShowing = true
		this.header.current.classList.remove('header--hidden')
	}

	onBurgerClick() {
		this.setState({menuOpen: !this.state.menuOpen})
		window.scroll && this.state.menuOpen ? window.scroll.start() : window.scroll.stop()
	}

	render() {
		return(
			<HeaderWrapper ref={this.header}>
				<Container>
					<Row justify="between" align="center">
						<Col xs="content">
							<LogoWrapper to="/" aria-label="Accueil">
								<Logo/>
							</LogoWrapper>
						</Col>
						<Col xs="content">
							<Navigation toggle={this.state.menuOpen} />
						</Col>
						<Toggler burgerEvent={this.onBurgerClick.bind(this)} toggle={this.state.menuOpen} />
					</Row>
				</Container>
			</HeaderWrapper>
		)
	}
}

import React, { useEffect, useRef, useContext } from 'react'
import gsap from 'gsap'
import { ContextLoader } from '../../context'
import { useStaticQuery, graphql } from 'gatsby'
import SplitText from '../splitText'
import styled from 'styled-components'

const LoaderWrapper = styled.div`
	position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: white;
  z-index: 15;
	pointer-events: none;
`

const LoaderTitle = styled.div`
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	font-family: ${props => props.theme.fonts.headings};
	color: ${props => props.black ? 'black' : 'white' };
	text-transform: uppercase;
	font-weight: 700;
	font-size: 20px;

	.char {
		display: inline-block;
		transform: translateY(15px);
		opacity: 0;
	}
`

const LoaderBack = styled.div`
	overflow: hidden;
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: black;
	transform-origin: left center;
`

const Loader = () => {
  const loaderEl = useRef(null)
  const loaderText = useRef(null)
  const split = useRef(null)
  const loaderBackgroundText = useRef(null)
  const loaderBackground = useRef(null)
  // eslint-disable-next-line
	const [isLoading, updateIsLoading] = useContext(ContextLoader)
	const data = useStaticQuery(graphql`
		query titleSite {
			wordpress {
				allSettings {
					generalSettingsTitle
				}
			}
		}
	`)

	// General
	useEffect(() => {
		document.body.classList.add('is-loading')
	}, [])

	useEffect(() => {
			hideLoader().then(() => {
				updateIsLoading(false)
			})
	}, [updateIsLoading])

  const hideLoader = () => {
		return new Promise((resolve) => {
			const tl = gsap.timeline({
				onComplete: () =>  resolve()
			})
	
			tl.to(split.current.getSplittedText().chars, {
				delay: 0.5,
				alpha: 1,
				y: 0,
				duration: 0.6,
				ease: 'power2.out',
				stagger: 0.05
			})
	
			tl.to(loaderBackground.current, {
				x: '-100vw',
				duration: 1.2,
				ease: 'power3.inOut'
			}, '-=0.4')
	
			tl.to(loaderBackgroundText.current, {
				x: '100vw',
				duration: 1.2,
				ease: 'power3.inOut'
			}, '-=1.2')
	
			tl.to(loaderEl.current, {
				alpha: 0,
				duration: 1,
				ease: 'power3.inOut'
			}, '-=0.3')
		})
  }

	return (
		<LoaderWrapper ref={loaderEl}>
			<LoaderTitle black ref={loaderText}>{data.wordpress.allSettings.generalSettingsTitle}</LoaderTitle>
			<LoaderBack ref={loaderBackground}>
				<LoaderTitle ref={loaderBackgroundText}>
					<SplitText splitBy="chars" ref={split}>
						{data.wordpress.allSettings.generalSettingsTitle}
					</SplitText>
				</LoaderTitle>
			</LoaderBack>
		</LoaderWrapper>
	)
}

export default Loader
import React from 'react'
import styled from 'styled-components'
import { Visible, Col } from 'react-grid-system'

const TogglerWrapper = styled.div`
  position: relative;
  width: 20px;
  height: 7px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 10;
  cursor: pointer;
  outline: none;
`

const TogglerLine = styled.span`
  width: 100%;
  height: 1px;
  background-color: black;
  transition: transform 0.6s ${props => props.theme.eases.out};

  &:nth-child(1) {
    transform-origin: left;
    transition-delay: 0.6s;
  }

  &:nth-child(2) {
    transform-origin: right;
    transition-delay: 0.6s;
  }

  &:nth-child(3),
  &:nth-child(4) {
    position: absolute;
    top: 50%;
    left: 50%;
    overflow: hidden;
    transform-origin: center;
  }

  &:nth-child(3) {
    transform: translate3d(-50%, -50%, 0) rotate(45deg) scaleX(0);
    transition-delay: 0.1s;
  }

  &:nth-child(4) {
    z-index: 1;
    height: 7px;
    background-color: white;
    transform: translate3d(-50%, -50%, 0) rotate(-45deg) scaleX(0);
    transition-delay: 0.3s;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 3px;
      width: 100%;
      height: 1px;
      background-color: black;
    }
  }

  ${props => props.toggle ?
    `
      &:nth-child(1),
      &:nth-child(2) {
        transform: scaleX(0);
        transition-delay: 0.05s;
      }

      &:nth-child(3) {
        transition-delay: 0.5s;
        transform: translate3d(-50%, -50%, 0) rotate(45deg) scaleX(1);
      }

      &:nth-child(4) {
        transition-delay: 0.7s;
        transform: translate3d(-50%, -50%, 0) rotate(-45deg) scaleX(1);
      }
    `
    : ``
  }
`

const Toggler = (props) => {
  return (
    <Visible sm>
      <Col xs="content">
        <TogglerWrapper onClick={props.burgerEvent} onKeyDown={props.burgerEvent} role="button" aria-label="Menu" tabIndex={0}>
          <TogglerLine toggle={props.toggle}/>
          <TogglerLine toggle={props.toggle}/>
          <TogglerLine toggle={props.toggle}/>
          <TogglerLine toggle={props.toggle}/>
        </TogglerWrapper>
      </Col>
    </Visible>
  )
}

export default Toggler

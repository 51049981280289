import { setConfiguration } from 'react-grid-system';
// import 'locomotive-scroll/dist/locomotive-scroll.min.css'

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Header from '../components/header'
import Footer from '../components/footer'
import Loader from '../components/loader'
import { ContextLoader, TransitionContext } from '../context'
import Scroll from '../components/scroll'
import GlobalStyle from '../components/GlobalStyle'
import GlobalVariables from '../components/GlobalVariables'
import { ThemeProvider } from 'styled-components'
import Transition from '../components/transitions'
import Cookie from '../components/cookie'

setConfiguration({
  breakpoints: [768, 1024, 1200, 1400, 1600],
  containerWidths: [720, 960, 1140, 1200, 1550],
  gridColumns: 12
})

const Layout = (props) => {
  const [isLoading, updateIsLoading] = useState(true)
  const [transition, setTransition] = useState('default')
  const value = { transition, setTransition }

  return (
    <>
    <ContextLoader.Provider value={[isLoading, updateIsLoading]}>
      <TransitionContext.Provider value={value}>
        <ThemeProvider theme={GlobalVariables}>
          <GlobalStyle />
          <Loader/>
          <Header smartHeader={true} />
          <Transition location={props.location}>
            <Scroll>
              <main>{props.children}</main>
              <Footer/>
            </Scroll>
          </Transition>
          <Cookie/>
        </ThemeProvider>
      </TransitionContext.Provider>
    </ContextLoader.Provider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
